import React from "react";
import { Button } from "@mui/material";
import "./StyledButton.css";

interface ButtonProps {
  label: string;
  dataTestid: string;
  handleClick: () => void;
  disabled?: boolean;
  variant?: "text" | "outlined" | "contained" | undefined;
  className?: string;
}

export const StyledButton = (props: ButtonProps) => {
  return (
    <Button
      className={`custom-button ${props.className}`}
      data-testid={props.dataTestid}
      variant={props.variant}
      onClick={props.handleClick}
      disabled={props.disabled}
    >
      {props.label}{" "}
    </Button>
  );
};
