import { useState } from "react";
import { getApplicationConfiguration } from "../lib/wordpressConfigQueryGenerator";
import { loadDataFromGraphql } from "../lib/graphQLCommunicator";
import {
  CmsParser,
  CtaParser,
  ErrorMessagingParser,
  FooterParser,
  GettingStartedParser,
  HomePageHeaderParser,
  HomePageStatsParser,
  HookContentParser,
  ResourceArticleParser,
  ResourcesParser,
  ResultPageHeaderParser,
  YellowPurpleBlocksParser,
} from "./cmsParsers/wordpressCmsParsers";
import { HomePageConfig, ResultPageConfig } from "../lib/types/cmsConfigs";

export const useHomePageEndpoint = () => {
  const [homePageConfig, setHomePageConfig] = useState<HomePageConfig>();

  const loadHomePage = async () => {
    const data = await loadDataFromGraphql(getApplicationConfiguration());
    const homePageConfig = {
      header: parsePageData(HomePageHeaderParser, data, "homePageHeader"),
      cta: parsePageData(CtaParser, data, "sharedCta"),
      hookContent: parsePageData(
        HookContentParser,
        data,
        "homePageHookContent",
      ),
      yellowPurpleBlocks: parsePageData(
        YellowPurpleBlocksParser,
        data,
        "homePageYellowPurpleBlocks",
      ),
      stats: parsePageData(HomePageStatsParser, data, "homePageStats"),
      gettingStarted: parsePageData(
        GettingStartedParser,
        data,
        "gettingStarted",
      ),
      resources: {
        ...parsePageData(ResourcesParser, data, "resources"),
        articles: parseArticleData(
          ResourceArticleParser,
          data,
          "moreResourcesArticles",
        ),
      },
      footer: parsePageData(FooterParser, data, "sharedFooter"),
      errorMessaging: parsePageData(
        ErrorMessagingParser,
        data,
        "sharedErrorMessaging",
      ),
    };
    setHomePageConfig(homePageConfig);
  };

  return { loadHomePage, homePageConfig };
};

export const useResultPageEndpoint = () => {
  const [resultPageConfig, setResultPageConfig] = useState<ResultPageConfig>();

  const loadResultPage = async () => {
    const data = await loadDataFromGraphql(getApplicationConfiguration());
    const resultPageConfig = {
      header: parsePageData(ResultPageHeaderParser, data, "resultPageHeader"),
      cta: parsePageData(CtaParser, data, "sharedCta"),
      gettingStarted: parsePageData(
        GettingStartedParser,
        data,
        "gettingStarted",
      ),
      resources: {
        ...parsePageData(ResourcesParser, data, "resources"),
        articles: parseArticleData(
          ResourceArticleParser,
          data,
          "moreResourcesArticles",
        ),
      },
      footer: parsePageData(FooterParser, data, "sharedFooter"),
      errorMessaging: parsePageData(
        ErrorMessagingParser,
        data,
        "sharedErrorMessaging",
      ),
    };
    setResultPageConfig(resultPageConfig);
  };

  return { loadResultPage, resultPageConfig };
};

function parsePageData(parser: CmsParser, data: any, key: string) {
  return parser.parse(data["data"]["page"][key]);
}

function parseArticleData(parser: CmsParser, data: any, key: string) {
  return parser.parse(data["data"][key]["edges"]);
}
