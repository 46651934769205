import React from "react";
import { AddressSearch } from "../../common/AddressSearch";
import { HtmlRenderer } from "../../common/HtmlRenderer";
import { Card, CardActions, InputLabel } from "@mui/material";
import "./AddressLookupSection.css";
import Grid from "@mui/material/Unstable_Grid2";
import { HomePageConfig } from "../../lib/types/cmsConfigs";
import { ReactComponent as Underline } from "../../assets/Underline.svg";
import LocationIcon from "../../assets/Pin.png";

type AddressLookupSectionProps = {
  config: HomePageConfig;
};

export const AddressLookupSection = ({ config }: AddressLookupSectionProps) => {
  return (
    <Card className="address-lookup-container homepage-hero-section-container">
      {config.header.videoUrl && (
        <iframe
          title="homepage-background"
          src={config.header.videoUrl}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
      <Grid container className="homepage-hero-section-content">
        <Grid xs={12} md={12}>
          <header className="hero-header">
            <img alt="location" className="location" src={LocationIcon} />
            {config.header.inputLabel && (
              <InputLabel
                disableAnimation={true}
                shrink
                className="address-search-label"
              >
                {config.header.inputLabel}
              </InputLabel>
            )}
            <h1 className="hero-header-heading">
              <HtmlRenderer html={config.header.headingHtml} />
            </h1>
            <div className="underline-container">
              <Underline />
            </div>
            <div>
              <p className="hero-header-text">Use our <b>School Board Finder</b> to get to know your school board.</p>
            </div>
          </header>

          <div className="home-page-search">
            <CardActions>
              <AddressSearch
                findAddressDataTestId="find-address-button"
                label={config.header.inputLabel}
                errorMessagingConfig={config.errorMessaging}
              ></AddressSearch>
            </CardActions>
          </div>
        </Grid>
        {/*
        <Grid xs={12} md={7}>
          <div className="hero-image">
            <img
              alt="logo"
              src={config.header.featureImageUrl}
              width="400px"
              height="400px"
            />
          </div>
        </Grid>*/}
      </Grid>
    </Card>
  );
};
