import React from "react";
import { AddressSearch } from "../../common/AddressSearch";
import "./FixedAddressSearch.css";
import { HomePageConfig } from "../../lib/types/cmsConfigs";

type AddressLookupSectionProps = {
  config: HomePageConfig;
};

export const FixedAddressSearch = ({ config }: AddressLookupSectionProps) => {
  return (
    <div className="fixed-search home-page-search ">
      <AddressSearch
        placeholder="Enter your address or ZIP code"
        id="fixed-search"
        findAddressDataTestId="find-address-button"
        errorMessagingConfig={config.errorMessaging}
      ></AddressSearch>
    </div>
  );
};
