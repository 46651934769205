export const getApplicationConfiguration = () => {
  return `
    query GetApplicationPage {
      page(id: "/home", idType: URI) {
        resultPageHeader {
          resultHeaderText
          resultHeaderInputLabel
        }

        homePageHeader {
          homePageHeaderHeading
          homePageHeaderInputLabel
          homePageVideoUrl
          homePageHeaderHeroImage {
            node {
              sourceUrl
            }
          }
        }

        sharedCta {
          sharedCta
        }

        homePageHookContent {
          homePageHookContentHeading
          homePageHookContentBody
          homePageHookContentCarousel {
            text
            image {
              node {
                sourceUrl
              }
            }
          }
        }
        
        homePageStats {
          homePageStatsHeading
          homePageStatsSubtext
          homePageStatsImageDesktop {
            node {
              sourceUrl
            }
          }
          homePageStatsImageMobile {
            node {
              sourceUrl
            }
          }
        }
    
        gettingStarted {
          gettingStartedHeading
          gettingStartedSubtext
          gettingStartedFeatureImage {
            node {
              sourceUrl
            }
          }
          gettingStartedButtonText
        }
    
        resources {
          resourcesHeading
          resourcesCopy
        }

        sharedFooter {
          footerContactHeaderText
          footerContactLinkText1
          footerContactLinkUrl1
          footerContactLinkText2
          footerContactLinkUrl2
          footerContactLinkText3
          footerContactLinkUrl3
          footerSocialHeaderText
          footerSocialFacebookUrl
          footerSocialTwitterUrl
          footerSocialInstagramUrl
          footerLegalHeaderText
          footerLegalLinkText1
          footerLegalLinkUrl1
          footerLegalLinkText2
          footerLegalLinkUrl2
        }

        sharedErrorMessaging {
          sharedAddressNotFoundErrorMessage
        }

        homePageYellowPurpleBlocks {
          purpleButtonLink {
            url
          }
          purpleButtonText
          purpleContent
          purpleTitle
          purpleImage {
            node {
              sourceUrl
            }
          }
          yellowButtonLink {
            url
          }
          yellowButtonText
          yellowContent
          yellowTitle
          yellowImage {
            node {
              sourceUrl
            }
          }
        }
      }

      moreResourcesArticles {
        edges {
          node {
            moreResourcesArticleGroup {
              moreResourcesArticleHeading
              moreResourcesArticleUrl
              moreResourcesArticleImage {
                node {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    }
  `;
};
