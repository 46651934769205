import React, { useEffect, useRef } from "react";
import "./YellowSection.css";
import Grid from "@mui/system/Unstable_Grid";
import { Stack } from "@mui/material";
import { HomePageConfig } from "../../lib/types/cmsConfigs";
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import YellowSectionImage from "../../assets/YellowRectangle.png";

interface YellowSectionProps {
  config: HomePageConfig;
}

export const YellowSection = ({ config }: YellowSectionProps) => {
  const imgRef = useRef(null);
  useEffect(() => {
    const el = imgRef.current;
    gsap.to(el, {
      scrollTrigger: {
          trigger: ".yellow-section-container",
          scrub: 1,
          start: 'top bottom',
          end: '+=5000',
      },
      rotation: 80,
      duration: 3,
      ease: "none",
    });
  }, []);
  return (
    <Stack className="people-section yellow-section-container">
      <Grid container className="address-lookup-container">
        <Grid xs={12} md={6} lg={6}>
          <h2 className="people-section-heading">
            {config.yellowPurpleBlocks.purpleTitle}
          </h2>
          <div className="people-section-subheading">
            {config.yellowPurpleBlocks.purpleContent}
          </div>
          <div className="people-section-button-container">
            <a
              className="button"
              href={config.yellowPurpleBlocks.purpleButtonLink}
            >
              {config.yellowPurpleBlocks.purpleButtonText}
            </a>
          </div>
        </Grid>
        <Grid xs={12} md={6} lg={6}>
          <div className="hero-image right">
            <img
              className="featured-image"
              alt="logo"
              src={config.yellowPurpleBlocks.yellowImageUrl}
            />
            <img
              alt="yellow section"
              src={YellowSectionImage}
              ref={imgRef}
            />
          </div>
        </Grid>
      </Grid>
    </Stack>
  );
};
