import React from "react";
import { CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import "./GetStarted.css";
import Grid from "@mui/system/Unstable_Grid";
import { GettingStartedConfig } from "../lib/types/cmsConfigs";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import { Link } from "./Link";

type GetStartedProps = {
  config: GettingStartedConfig;
};

export const GetStarted = ({ config }: GetStartedProps) => {
  const RESOURCE_LINK = "https://xqsuperschool.org/resources/";

  return (
    <Grid container className="get-started-container-root">
      <Grid xs={10} md={9} lg={8} xsOffset={1} mdOffset={1.5} lgOffset={2}>
        <Card className="get-started-container">
          <Grid container>
            <Grid xs={12} md={12} className="description-container">
              <CardContent className="starts-with-you-container">
                <h4 className="starts-with-you-header">{config.heading}</h4>
                <p className="starts-with-you-subtext">{config.subtext}</p>
              </CardContent>
              <CardActions>
                <Link href={RESOURCE_LINK} className="get-started-link">
                  <>
                    <span className="get-started-link-text">
                      {config.buttonText}
                    </span>
                    <EastRoundedIcon
                      sx={{ color: "primary" }}
                    ></EastRoundedIcon>
                  </>
                </Link>
              </CardActions>
            </Grid>
            <Grid xs={12} md={12}>
              <img
                className="get-started-image"
                src={config.featureImageUrl}
                title="get started"
                alt="get started"
              ></img>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
};
