import React from "react";
import "./About.css";

function About() {
  return (
    <div>
      <></>
    </div>
  );
}

export default About;
