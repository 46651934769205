import React from "react";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/system/Unstable_Grid";
import "./MoreResources.css";
import { ResourcesConfig } from "../lib/types/cmsConfigs";
import { Link } from "./Link";
import { ReactComponent as ResourcesHeadingCircle } from "../assets/ResourcesHeadingCircle.svg";

type MoreResourcesProps = {
  config: ResourcesConfig;
};

export const MoreResources = ({ config }: MoreResourcesProps) => {
  const numberOfArticles = config.articles?.length || 0;

  return (
    <Grid container>
      <Grid xs={12} md={10} mdOffset={1}>
        <div className="resources-container">
          <h2 className="resources-heading">
            {config.heading}
            <ResourcesHeadingCircle />
          </h2>
          <div className="resource-copy">{config.copy}</div>
          <div className="resource-cards-container">
            <Grid container spacing={4}>
              {config.articles?.map((article) => {
                return (
                  <Grid
                    xs={12}
                    md={6}
                    lg={numberOfArticles > 3 ? 3 : 4}
                    key={article.heading}
                  >
                    <Card className="link-container">
                      <Link href={article.url}>
                        <CardActionArea>
                          <CardMedia
                            sx={{ minHeight: 268, height: 268 }}
                            image={article.imageUrl}
                            title="resource image"
                          ></CardMedia>
                          <CardContent className="card-content-wrapper">
                            <h4>{article.heading}</h4>
                            <p>By Team XQ</p>
                          </CardContent>
                        </CardActionArea>
                      </Link>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
