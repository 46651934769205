import React, { useEffect, useState } from "react";
import { AddressLookupSection } from "./sections/AddressLookupSection";
import { useHomePageEndpoint } from "../hooks/wordpressCms";
import { Backdrop, CircularProgress } from "@mui/material";
import { Footer } from "../common/Footer";
import { PurpleSection } from "./sections/PurpleSection";
import { YellowSection } from "./sections/YellowSection";
import { FixedAddressSearch } from "./sections/FixedAddressSearch";
// import { HookContentSection } from "./sections/HookContentSection";
import "./Home.css";
import { HookContentSection } from "./sections/HookContentSection";

function Home() {
  const { loadHomePage, homePageConfig } = useHomePageEndpoint();
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    loadHomePage();
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 350);
      document.body.classList.toggle('show-search', window.scrollY > 350);
    });
  }, []);

  return (
    <div className={scroll ? "show-search" : "hide-search"}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal }}
        open={!homePageConfig}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {homePageConfig && (
        <>
          <AddressLookupSection config={homePageConfig} />
          <FixedAddressSearch config={homePageConfig} />
          <HookContentSection config={homePageConfig} />
          <YellowSection config={homePageConfig} />
          <PurpleSection config={homePageConfig} />
          <Footer config={homePageConfig.footer} />
        </>
      )}
    </div>
  );
}

export default Home;
